.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.modal-backdrop{
  z-index: 1500;
}

.modal{
  z-index: 2000;
}

.textAlignR{
  text-align: right;
}

.textAlignC{
  text-align: center;
}

.loginForm{
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%,-50%);
  width: 60%;
  background-color: white;
}

.formBox{
  background-color: #b5b5b578;
  height: 100vh;
  position: relative;
}

.imgDiv{
  width: 40%;
  height: 450px;
  float: left;
  background-image: url("./Assesst/images/Mangalbazar.jpg");
  background-repeat: no-repeat;
  background-size: cover;
}

.formDiv{
  width : 60%;
  float: right;
  padding: 60px 20px 100px 20px;
}
.across {
   padding-bottom: 30px;
}
.loginBtn{
  width : 100px;
  border: 1px solid cadetblue;
  background-color: cadetblue;
}

.loginBtn:hover{
  background-color: cadetblue;
  border: 1px solid cadetblue;

}
.loading{
  position : absolute;
  left: 50%;
  top : 50%;
  transform: translate(-50%,-50%);
}

.hoverRed:hover {
  background-color: Red;
  cursor: pointer;
}

.removeReference{
  color : red;
}

.removeReference:hover{
  cursor: pointer;
}

.marginTop{
  margin-top: 15px;
}

.showPW{
  cursor: pointer;
  color: gray;
  font-size: 14px;
}

.showPWicon{
  padding-right: 7px;
  cursor: pointer;
  color: gray;
  font-size: 14px;
}

.pwDiv{
  width: 100%;
}
.float-left{
  float: left;
}

.float-right{
  float: right;
}

.showPW:hover{
  color: gray;
}

.forgotPW{
  font-size: 12px;
}

.textAlign-left {
  text-align: left;
}

.marginBtm-10 {
  margin-bottom: 10px;
}

.image {
  max-height: 150px;
}
@media screen and (max-width: 425px) {
  .imgDiv{
    display: none;
  }

  .formDiv{
    width: 100%;
  }
}