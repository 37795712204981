.width100{
    width: 100%;
}

.marginLR-5{
    margin: 0px 5px;
}


.padding-10{
    margin: 10px ;
}
.textA-L{
    text-align: left;
}